<template>
    <div>
      <Card class="card-custom">
        <template #title>
          <div class="d-flex justify-end">
            <div class="column-three"></div>
            <!-- <h4 class="column-three">{{ $i18n.t('search') }}</h4> -->
            <div class="column-three d-flex justify-end">
              <span class="p-buttonset">
                <Button @click="resetLocalOrders" :loading="loadingLayout" :disabled="!hasDraggbleChanges" :label="$t('reset')" icon="pi pi-refresh" class="p-button-sm p-button-danger" />
                <Button @click="userConfirmChanges = true" :loading="loadingLayout" :disabled="!hasDraggbleChanges" :label="$t('save_layout')" icon="pi pi-save" class="p-button-sm p-button-success" />
            </span>
            </div>
          </div>
          
      </template>
      <template #content>
    </template>
  </Card>
      <v-row class="layout__row ma-0" style="color: var(--text-color)">
        <v-col v-if="gameBlockNotWork">
          <div class="main-content">
            <no-work-page 
            @refresh-request="toGetGameBlocks" 
            :loadingFlag="loadingFlag"
            :textError="textError"
            />
        </div>
        </v-col>
            <template v-else>
              <v-col class="mt-1 layout__col" >
                <div class="my-1" v-if="isAvailableGames">
                  <h3 class="text-uppercase"> {{ `${selectedProvider} - ${getBlockName}` }} </h3>
                  <Button
                     @click="returnToProviders"
                     icon="pi pi-arrow-left"
                       class="p-button-sm p-button-danger mb-1 px-8" />
                  <available-to-add-games
                  @update-block="toUpdateBlock"
                  :games="filteredAvailableGames"
                  :paggination="games"
                  :provider="selectedProvider"
                  :blockId="blockId"
                  :loadingFlag="loadingAvailableGames"
                  />
                </div>
                <template v-if="!isAvailableGames">
                  <div class="my-1" v-if="!isProvidersArray">
                    <div
                    class="d-flex flex-column align-center">
                      <h3 class="text-uppercase mb-n2"> {{ getBlockName ? `${$t('games')} - ${getBlockName}` : $t('games') }} </h3>
                      <span style="font-size:14px">({{ $t('draggble_hint') }})</span>
                      <div class="font-weight-bold text-uppercase c-red" 
                      v-if="!blockId && !loadingPage">
                       {{ $t('select_block_see_games') }}
                     </div>
                    </div>
                    <template v-if="blockId">
                     <Button
                      @click="showProvidersArray"
                       :loading="loadingFlag"
                       :label="$t('add_game')"
                         icon="pi pi-cog"
                          class="mb-1 p-button-sm p-button-success" />
                       <div class="font-weight-bold text-uppercase c-red" 
                       v-if="!gamesByBlock.length && !loadingPage">
                        {{ $t('no_game_by_block_add') }}
                      </div>
                 <games-by-block 
                 @update-block="toUpdateBlock"
                 :games="gamesByBlock"
                 :blockId="blockId"
                 :loadingFlag="loadingGamesByBlock"
                 @draggble-changed="setDraggbleChanged"
                       @save-items="saveGamesByBlocksOrder"
                       :triggerReset="triggerReset"
                       :triggerSave="triggerSave"
                 />
                   </template>
                   <ProgressBar v-if="loadingPage" mode="indeterminate" style="height: .5em" />
                 </div>
                 <providers-array 
                 :blockName="getBlockName"
                 @close-providers="isProvidersArray = false"
                 @select-provider="getSelectedProvider"
                 v-else
                 />
                </template>
              </v-col>
              <div class="line"></div>
              <v-col class="mt-1 layout__col">
                <div class="my-1">
                  <h3 class="text-uppercase mb-n2"> {{ $t('game_blocks') }} </h3>
                  <span style="font-size:14px">({{ $t('draggble_hint') }})</span>
                  <h2 v-if="gameBlocks.length === 0 && !loadingFlag && !loadingPage" class="text-uppercase my-1 c-red"> 
                    {{ $t('gameBlocks_not_found')
                  }} </h2>
                  <template v-if="gameBlocks.length && !loadingPage">
                    <game-blocks 
                    :blocks="gameBlocks"
                    :loadingFlag="loadingFlag"
                    @loading-start='loadingGamesByBlock = true'
                    @loading-end='loadingGamesByBlock = false'
                    @draggble-changed="setDraggbleChanged"
                    @save-items="saveBlocksOrder"
                    @update-block="toUpdateBlock"
                    :triggerUpdateBlock="triggerUpdateBlock"
                    :triggerReset="triggerReset"
                    :triggerSave="triggerSave"
                    @block-games="emitGamesByBlock"
                    />
                  </template>
                  <ProgressBar v-if="loadingPage" mode="indeterminate" style="height: .5em" />
                  </div>
              </v-col>
            </template>
      </v-row>
       <!-- Dialog to have unsaved changes -->
    <v-dialog v-model="userHaveUnsavedChanges" content-class="br-16" scrollable persistent max-width="350px"
    transition="dialog-transition">
<v-card tile>
<v-toolbar flat dark color="var(--main-flamingo)">
<div class="logo-absolute">
  <img src="@/assets/images/ADMIN_LOGO.png" alt="">
</div>
<v-spacer></v-spacer>
<v-toolbar-title class="font-weight-bold">{{ $t('alert') }}!</v-toolbar-title>
<span class="close-popup" @click="userHaveUnsaved = false"></span>
<v-spacer></v-spacer>
</v-toolbar>

<v-card-text>
<v-col class="d-flex justify-center">
              <span class="d-flex" style="color: #161421;font-size: 17px;line-height: 1.5;">
                {{$t('unsaved_layout') }}
              </span>
</v-col>
<v-card-actions class="d-flex justify-space-between" style="gap: 10px;">
  <v-col class="pa-0 text-right">
    <v-btn @click="discardChanges" color='var(--red)!important'
    style="width:105px"
           class="main-btn">{{ $t('discard') }}
    </v-btn>
  </v-col>

  <v-col class="pa-0 text-left">
    <v-btn @click="saveLayoutAndRedirect" style="width:105px" class="bg-c-green c-white">{{ $t('save') }}</v-btn>
  </v-col>
</v-card-actions>
</v-card-text>
</v-card>
</v-dialog>
<!-- Dialog to confirm changes -->
<v-dialog v-model="userConfirmChanges" content-class="br-16" scrollable max-width="350px"
    transition="dialog-transition">
<v-card tile>
<v-toolbar flat dark color="var(--main-flamingo)">
<div class="logo-absolute">
  <img src="@/assets/images/ADMIN_LOGO.png" alt="logo">
</div>
<v-spacer></v-spacer>
<v-toolbar-title class="font-weight-bold">{{ $t('toConfrim') }}</v-toolbar-title>
<v-spacer></v-spacer>
</v-toolbar>

<v-card-text>
              <div class="d-flex c-text font-weight-bold justify-center">
                {{$t('confirm_layout') }}
              </div>
<v-card-actions>
  <span class="p-buttonset fw">
    <Button @click="userConfirmChanges = false" :label="$t('cancelLabel')" class="p-button-sm p-button-danger" />
    <Button @click="confirmLayoutChanges" :label="$t('save')" class="p-button-sm p-button-success" />
  </span>
</v-card-actions>
</v-card-text>
</v-card>
</v-dialog>
    </div>
  </template>
  
  <script>
  import GameBlocks from './GameBlocks'
  import GamesByBlock from './GamesByBlock.vue';
  import ProvidersArray from './ProvidersArray.vue';
  import AvailableToAddGames from './AvailableToAddGames.vue';

  import { Drag, DropList } from "vue-easy-dnd";
  import { mapGetters } from "vuex";
  export default {
    name: "ByBlock",
    // props: ['providerName', 'gameName'],
    components:{
      AvailableToAddGames,
      ProvidersArray,
      GameBlocks,
      GamesByBlock,
      Drag,
      DropList,
    },
    props: {
  },
    data() {
      return {
        loadingFlag: true,
        loadingPage: true,
        loadingModify: true,
        loadingGamesByBlock: false,
        loadingAvailableGames: true,
        gameBlockNotWork: false,
        textError: 'game_block_error',
        blockId: 0,
        gamesByBlock: [],
        triggerUpdateBlock: false,
        isProvidersArray: false,
        isAvailableGames: false,
        selectedProvider: '',
        userConfirmChanges: false,
        userHaveUnsavedChanges: false,
        hasDraggbleChanges: false,
        loadingLayout: false,
        triggerReset: false,
        triggerSave: false,
      }
    },
    async mounted() {
      if (this.gameBlocks.length === 0) {
        await this.toGetGameBlocks();
      } else{
        this.disabledAllFlags();
      }
    },
    watch: {
    filteredGamesByBlock: function(newVal, oldVal) {
      if (this.filteredGamesByBlock) {
        if (newVal.length === 0) {
          this.handleGamesChange();
        }
      }
    }
    },
    computed:{
      ...mapGetters({
        gameBlocks: 'gameBlocks/getGameBlocks',
        games: 'gameBlocks/getGamesArray',
      }),
      getBlockName(){
        if(this.blockId){
          const find = this.gameBlocks.find(block => block.block_id === this.blockId).block_name;
          if(find){
            return find
          } else{
            return ''
          }
        } else {
          return ''
        }
      },
      
      filteredAvailableGames() {
        // console.log('item', this.games.items);
        // console.log('block',this.gamesByBlock);
      if (this.games.items && this.gamesByBlock) {
      return this.games.items.filter(game => {
        // check games in selectBlock.games
        return !this.gamesByBlock.some(selectedGame => selectedGame.game_name === game.name);
      });
    } else{
      return []
    }
    }
    },
    methods: {
      discardChanges(){
        this.triggerReset = true;
       this.userHaveUnsaved = false;
       this.hasDraggbleChanges = false;
       this.$toast.add({ severity: 'info', summary: this.$t('discard_layout'), life: 4000 })
       this.redirectAfterLayoutPopup();
     },
     confirmLayoutChanges(){
      this.loadingFlag = true;
      this.triggerSave = true;
      this.loadingFlag = false;
      this.hasDraggbleChanges = false;
      this.userConfirmChanges = false;
    },
     async saveLayoutAndRedirect(){
       this.confirmLayoutChanges();
       this.redirectAfterLayoutPopup();
     },
     redirectAfterLayoutPopup(){
      this.$root.$emit('layoutOpenMenu');
      if (this.$route.path === '/game-manager/blocks') {
        this.$router.push(this.linkToRedirect);
      }
      this.$store.commit('linksHref/setLinkHref', '');
    },
    async resetLocalOrders(){
      this.triggerReset = true;
      this.hasDraggbleChanges = false;
    },
    reset(){
      this.$nextTick(() => this.triggerReset = false);
    },
      returnToProviders(){
        this.isAvailableGames = false;
        this.isProvidersArray = true;
      },
      async getSelectedProvider(providerString){
        this.loadingAvailableGames = true;
        this.isAvailableGames = true;
        this.isProvidersArray = false;
        this.selectedProvider = providerString
        const filters = {
          provider: providerString,
          exclude_block_id: this.blockId,
          page: 1,
          size: 25
        }
        try {
          await this.$store.dispatch('gameBlocks/getGamesArray', filters);
          // await this.$store.dispatch('gameBlocks/awaitGetAvailableGamesForGameBlock', filters);
          
        } catch (error) {
          this.$toast.add({
                severity: 'error', summary: this.$t("just_error"), life: 4000,
            });
        } finally{
          this.loadingAvailableGames = false;
        }
      },
      showProvidersArray(){
        this.isProvidersArray = true
      },
      toUpdateBlock(){
        this.triggerUpdateBlock = true;
        this.$nextTick(() => this.triggerUpdateBlock = false);
      },
      setDraggbleChanged(){
        this.hasDraggbleChanges = true;
      },
      emitGamesByBlock(data){
      // console.log(data);
        const {items, blockId} = data
        this.gamesByBlock = items || [];
        this.blockId = blockId || 0;
      },
      async toGetGameBlocks(){
        try {
            await this.$store.dispatch('gameBlocks/awaitGetGameBlocks');
            //  this.toSelectGameBlock(this.gameBlocks[0], 0);
            // await this.applyProviderFilters();
            this.gameBlockNotWork = false;
          } catch (error) {
            this.gameBlockNotWork = true;
            this.$toast.add({
                severity: 'error', summary: this.$t("just_error"), life: 4000,
            });
          } finally{
            this.disabledAllFlags();
          }
    },
    saveGamesByBlocksOrder(data){
      const { items, first } = data;
        // Update orders
        const editedGameOrder = items.map((item, index) => ({
          game_id: item.game_id,
          order: first + index * 10, 
        }));
        const gameBlockData =
              {
          block_id: this.blockId,
          games: editedGameOrder
        }
        // 
        const end = 'gameBlocks/awaitUpdateGameOrders';
        // 
        this.saveLayoutOrders(end, gameBlockData);
    },
    saveBlocksOrder(data){
      const { items, first } = data;
        // Update orders
        const blocksData = items.map((item, index) => ({
          block_order: index, 
          block_id: item.block_id,
          block_name: item.block_name,
          is_active: item.is_active,
        }));
        const end = 'gameBlocks/awaitUpdateGameBlocks';
        this.saveLayoutOrders(end, blocksData);
      },
      async saveLayoutOrders(endpoint, data){
        await this.$store.dispatch(endpoint, data);
      try {
        this.$root.$emit('setUnblock');
        this.$toast.add({
          severity: 'success', summary: this.$t("gameBlock_is_changed"), life: 4000,
        })
        this.triggerSave = true;
      } catch (error) {
        this.$toast.add({
        severity: 'error', summary: this.$t("just_error"), life: 4000,
      })
        
      }

    },
    disabledAllFlags() {
      this.loadingModify = false;
      this.loadingPage = false;
      this.loadingBlocks = false;
      this.loadingFlag = false;
      this.loadingGamesByBlock = false;
    },
    //========================================================================================================================================================
    
      handleGamesChange(){
        if (
          this.selectBlock && 
          this.filteredGamesByBlock && 
          this.localGamesOrder && 
          this.filteredGamesByBlock.length === 0 && 
          this.localGamesOrder.length > 0
        ) {
          if(this.game.page !== this.game.pages){
            this.nextFilters();
          } else{
            this.isNoGameToAdd = true;
          }
        }
      },
      //========================================================================================================================================================
    },
  
  }
  </script>
  
  <style lang="scss" scoped>
  ._cursor-grab{
    cursor: grab;
  }
  ._cursor-pointer{
    cursor: pointer;
  }
  .load-photo {
    align-items: center;
    background: #f2f6f9 !important;
    border: 2px dashed #dfe4e8 !important;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 5px !important;
    &_provider{
      background: #0000009e !important;
      border: 2px dashed #000 !important;
    }
    span {
      cursor: pointer;
    }
  }
  .layout{
    
    @media (max-width:850px){
      min-height: 100%;
    }
    &__row{
      @media (max-width:850px){
        display: block;
      }
    }
    &__col{
  
    }
  }
  .line{
    width: 1px;
    border: 2px dashed var(--surface-400) !important;
    @media (max-width:850px){
      display: none;
    }
  }
  
  .custom-overlay{
    background-color: rgb(255 255 255 / 0.3)!important;
        backdrop-filter: blur(10px);
  }
  .logo-absolute{
    position: absolute;
    width: 60px;
    height: 60px;
    left: 5px;
    top: 5px;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  .close-popup{ 
      display: block;
      position: absolute;
      right: 25px;
      top: 30px;
      width: 25px;
      height: 18px;
      z-index: 4;
      cursor: pointer;
      span,
      &::before,
      &::after {
        content: "";
        transition: all 0.3s ease 0s;
        right: 0;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--white);
      }
      &::before {
        top: calc(50% - toRem(1));
        transform: rotate(-45deg);
      }
      &::after {
        bottom: calc(50% - toRem(1));
        transform: rotate(45deg);
      }
      span {
        width: 0;
      }
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .main-item {
    overflow: auto;
    max-height: 575px;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    position: relative;
    height: 100%;
    &__game {
      padding: 3px 8px;
      display: flex;
      align-items: center;
      border: 1px solid rgba(225, 225, 225, 0.5);
      &:nth-of-type(odd) {
      background-color: var(--surface-100);
    }

    &:nth-of-type(even) {
      background-color: var(--surface-300);
    }

    &._provider  {
      &:nth-of-type(even) {
        background-color: var(--surface-100);
      }

      &:nth-of-type(odd) {
        background-color:  var(--surface-300);
      }
    }
    &._active{
      border: 1px solid var(--pink-700);
      background-color: var(--pink-200) !important;
    }
    }
    &__drag{
      margin-right: 5px;
      i{
        color: var(--text-color-secondary);
        ._active &{
          color: var(--bluegray-900);
        } 
      }
    }
    &__image {
      flex: 0 0 50px;
      width: 50px;
      height: 50px;
      position: relative;
      margin-right: 4px;
      ._provider &{
        padding: 0 5px;
        background-color: var(--main-flamingo);
        display: flex;
        justify-content:center;
        align-items: center;
        cursor: pointer;
        border-radius: 16px;
      }
      ._no-active &{
        filter: grayscale(1);
      }
      span{
        display: flex;
        justify-content:center;
        align-items: center;
        background-color: var(--red);
        color: var(--white);
        min-width: 20px;
        height: 20px;
        position: absolute;
        padding: 0 3px;
        border-radius: 50px;
        right: -5px;
        top: -5px;
        z-index: 2;
      }
      img{
        border-radius: 16px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    &__name {
      flex: 1 1 100%;
      margin: 0 8px;
      ._provider &{
        display: flex;
        justify-content:center;
        align-items: center;
      }
    }
    &__actions {
      display: flex;
      justify-content:center;
      align-items: center;
    }
    &__edit {
    }
  }
  //.main-item {
  //  display: flex;
  //  flex-direction: column;
  //  row-gap: 1px;
  //
  //  &__game {
  //    padding: 3px 8px;
  //    display: flex;
  //    align-items: center;
  //    border: 1px solid rgba(225, 225, 225, 0.5);
  //    
  //  
  //  }
  //  &__image {
  //    display: flex;
  //    justify-content:center;align-items: center;
  //    cursor: pointer;
  //    margin-right: 8px;
  //    flex: 0 0 50px;
  //    width: 50px;
  //    height: 50px;
  //    position: relative;
  //    background-color: var(--main-flamingo);
  //    border-radius: 16px;
  //    span{
  //      display: flex;
  //      justify-content:center;align-items: center;
  //      background-color: var(--red);
  //      color: var(--white);
  //      width: 20px;
  //      height: 20px;
  //      position: absolute;
  //      border-radius: 50px;
  //      right: -5px;
  //      top: -5px;
  //    }
  //    img{
  //      max-width: 100%;
  //      max-height: 100%;
  //      object-fit: contain;
  //      padding: 0 5px;
  //    }
  //  }
  //  &__name {
  //    flex: 1 1 100%;
  //    margin: 0 8px;
  //    display: flex;
  //    justify-content:center;align-items: center;
  //  }
  //  &__actions {
  //    display: flex;
  //    justify-content:center;align-items: center;
  //    
  //  }
  //  &__edit {
  //  }
  //}
  .item-title{
    flex: 0 1 33.333%;
    white-space: nowrap;
  }
  .card_style {
    margin-top: 10px;
    box-shadow: 0 0 5px #2c3e50;
    border-radius: 20px
  }
  
  #games_table_id {
  
    tr {
      font-size: 20px;
    }
  }
  .sticky-filters {
      width: 100%;
    z-index: 3;
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    @media screen and (max-width: 960px), (max-height: 500px) and (orientation: landscape) {
        position: static !important;
    }
  }
  </style>
  