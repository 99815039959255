<template>
        <div>
            <div class="my-1">
              <h3 class="text-uppercase mb-n1" >{{ `${$t('game_studios')} - ${filteredProviders.length}` }} ({{ blockName }} - Block) </h3>
            </div>
            <div class="d-flex justify-center align-center" >
                <Dropdown v-model="filters.provider_id"
                :disabled="loadingFlag" 
                  :loading="loadingFlag" 
                  optionLabel="name"
                  optionValue="id"
                  dataKey="id"
                  :placeholder="$t('provider')" 
                  :options="availableProviders" 
                  :emptyFilterMessage="$t('provider_not_found')">
                
                
                  <template #option="slotProps">
                    <div class="provider-complete">
                      <div class="provider-complete__logo">
                          <img :src="slotProps.option.logo" />
                      </div>
                      <div class="provider-complete__name">
                          <div>{{slotProps.option.name}} ({{ slotProps.option.aggregator }})</div>
                      </div>
                    </div>
                </template>
                </Dropdown>
                  <Button 
                  :disabled="loadingFlag" 
                  :loading="loadingFlag" 
                  v-if="filters.provider" 
                  @click="clearFilters" 
                  :label="$t('delete')" 
                  icon="pi pi-trash" class="p-button-sm p-button-danger ml-1" />
            </div>
            <Button
               @click="closeProviders"
               icon="pi pi-undo"
               :label="$t('return_block_games')"
                 class="p-button-sm p-button-danger my-2" />
            <h2 v-if="!provider.length && !loadingFlag" class="text-uppercase my-1 c-red"> {{ $t('game_studios_not_found') }} </h2>
              <div class="main-item" v-if="provider.length && !loadingFlag">
                
              <div class="main-item__game _cursor-pointer" 
              @click.stop="selectProvider(item.string_id)" 
              :class="{ '_no-active' : !item.is_enabled }" 
              v-for="(item, index) in filteredProviders" :key="index" >
              <div class='main-item__column'>
                  <div class="main-item__image" v-if="item.logo">
                    <v-lazy v-model="isActive">
                      <v-img max-height="50px" max-width="50px" 
                      @load="isActive = true" 
                      :src="item.logo" 
                      :alt="item.name" />
                    </v-lazy>
                  </div>
                  <button type="button" v-if="item.aggregator" 
                @click.stop="$router.push('/game-fee-settings/aggregators')"
                class="custom-badge _main"
                >
                  <span>{{ item.aggregator }} </span>
                </button>
                </div>
                <div class='main-item__column main-item__column_main'>
                  <div class="main-item__name">
                    <span>{{ item ? `${item.name} (${item.total_games} ${$t('ga')}) ` : '' }} </span>
                  </div>
                </div>
                <div class='main-item__column main-item__column_actions'>
                  <span class="lh-1 white-space custom-badge _main" v-if="!item.is_enabled"> {{ $t('not_active') }}</span>
                
                <div class="main-item__actions">
                </div>
              </div>
              </div>
            </div>
            <item-loading v-if="loadingFlag" />
        </div>
        
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: 'ProvidersArray',
        props: {
            blockName: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                isActive: false,
                loadingFlag: false,
                filters:{
                    provider_id: '',
                    size: 100,
                    page: 1,
                },
                availableProviders:[],
            }
        },
        computed: {
            ...mapGetters({
                provider: 'providers/getFilteredProviders',
            }),
            filteredProviders(){
                if (this.provider) {
                    const filterArray = this.availableProviders = this.provider
                    // console.log('filterArray', filterArray);
                    if (this.filters.provider) {
                        return filterArray.filter(item => item.id === this.filters.provider_id)
                    } else{
                        return filterArray
                    }
                } else{
                    return []
                }
      },

        },
        async mounted(){
            this.loadingFlag = true;
            if (!this.provider.length) {
                try {
                    await this.$store.dispatch('providers/awaitGameProviders', {filters: this.filters, type: this.isAgent});
                } catch (error) {
                    console.log(error);
                    this.$toast.add({
          severity: 'error', summary: this.$t("just_error"), 
          detail: error.message,
          life: 4000,
        })
                }
            }
            this.loadingFlag = false;
        },
        methods:{
            clearFilters(){
                this.filters.provider_id = '';
            },
            closeProviders(){
                this.$emit('close-providers')
            },
            selectProvider(name){
                this.$emit('select-provider', name)
            },
        },
    }
</script>

<style lang="scss" scoped>
.main-item {
    overflow: auto;
    max-height: 575px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    position: relative;
    height: 100%;
    &__game {
      padding: 3px 8px;
      display: flex;
      align-items: center;
      border: 1px solid var(--surface-500);
      border-radius: 4px;
      background-color: var(--surface-200);
      //&:nth-of-type(even) {
      //  background-color: var(--surface-100);
      //}
//
      //&:nth-of-type(odd) {
      //  background-color:  var(--surface-300);
      //}
    &._active{
      border: 1px solid var(--pink-700);
      background-color: var(--pink-200) !important;
    }
    }
    &__column{
      display: flex;
      align-items: center;
      flex: 0 1 33.333%;
      &_actions{
        justify-content: flex-end;
      }
      &_main{
        //flex: 1 1 100%;
      }
    }
    &__drag{
      margin-right: 5px;
      i{
        color: var(--text-color-secondary);
        ._active &{
          color: var(--bluegray-900);
        } 
      }
    }
    &__image {
      flex: 0 0 50px;
      width: 50px;
      height: 50px;
      position: relative;
      margin-right: 4px;
      line-height: 1;
      padding: 0 2px;
      background-color: var(--main-flamingo);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 16px;

    ._no-active & {
      filter: grayscale(1);
    }
      span{
        display: flex;
        justify-content:center;
        align-items: center;
        background-color: var(--red);
        color: var(--white);
        min-width: 20px;
        height: 20px;
        position: absolute;
        padding: 0 3px;
        border-radius: 50px;
        right: -5px;
        top: -5px;
        z-index: 2;
      }
      img{
        border-radius: 0;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    &__name {
      flex: 1 1 100%;
      margin: 0 8px;
        display: flex;
        justify-content:center;
        align-items: center;
    }
    &__actions {
      display: flex;
      justify-content:center;
      align-items: center;
    }
    &__edit {
    }
  }
</style>