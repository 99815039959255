<template>
    <div>
        <template v-if="!localLoadingFlag">
          <div class="main-item">
              <div class="main-item__game _cursor-pointer" 
              v-for="(item, index) in games" :key="index"
              :class="{ '_no-active': !item.active }"
              >
                <div class='main-item__column'> 
                    <div class="main-item__image" v-if="item.logo">
                      <v-lazy v-model="isActive">
                        <v-img height="50px" max-width="50px" 
                        @load="isActive = true" 
                        :src="item.logo" 
                        :alt="item.name" />
                      </v-lazy>
                    </div>
                    <button type="button" v-if="item.aggregator" 
                @click.stop="$router.push('/game-fee-settings/aggregators')"
                class="custom-badge _main"
                >
                  <span>{{ item.aggregator }} </span>
                </button>
                    </div>
                    <div class='main-item__column main-item__column_main'>
                      <div class="main-item__name" v-if="item.name">
                        {{ item.name }}
                      </div>
                    </div>
                    <div class='main-item__column main-item__column_actions'>
                      <div class="main-item__actions space-x-1">
                        <span class="lh-1 white-space custom-badge _main" v-if="!item.active"> {{ $t('not_active') }}</span>
                        <Button 
                        v-tooltip.top="$t('tooltip_add_game_to_block')"
                        :disabled="item.loadingItem" 
                        :loading="item.loadingItem" 
                        @click="toAddGameInBlock(item)" icon="pi pi-plus" class="p-button-sm p-button-success px-8" />
                      </div>
                    </div>
              </div>
          </div>
          <div v-if="!games.length" class="d-flex flex-column align-center">
            <span class="font-weight-bold text-uppercase c-red">
              {{ $t('no_game_to_add') }}
              </span>
              </div>
            </template>
            <item-loading v-else />
            <games-paggination 
            v-if="games.length && paggination.pages !== 1"
                        :pagginationObject="filters" 
                        :requestData="paggination" 
                        @apply-filters="applyFilters" 
                        :isStatic="true"
                        />
                        
    </div>
</template>

<script>
import GamesPaggination from '@/views/GamesPaggination'
    export default {
        name: 'AvailableToAddGames',
        components:{
            GamesPaggination
        },
        props:{
            games:{
                type: Array,
                required: true,
            },
            blockId:{
                type: Number,
                required: true,
            },
            paggination:{
                type: Object,
                required: true,
            },
            provider:{
                type: String,
                required: true,
            },
            loadingFlag:{
              type: Boolean,
              required: true,
            }
        },
        data() {
            return {
              localLoadingFlag: false,
                isActive: false,
                filters:{
                  exclude_block_id: this.blockId,
                  size: 25,
                  page: 1,
                },
            }
        },
        watch: {
          blockId: {
            //immediate: true,
            handler: function (val, oldVal) {
              if (val) {
              this.filters.exclude_block_id = val
                this.applyFilters();
            }
            }
          },
          provider: {
            // deep: true,
            immediate: true,
            handler: function (val, oldVal) {
              if (val) {
              this.filters.provider = val
            }
            }
          },
          loadingFlag: {
            immediate: true,
            handler: function (val, oldVal) {
              // console.log(val)
              this.localLoadingFlag = val
            }
          },
        },
        methods:{
          async toAddGameInBlock(game){
              game.loadingItem = true
              const data = {
                  block_id: this.blockId,
                  game_id: game.id
              }
              try {
                  await this.$store.dispatch('gameBlocks/awaitAddGameToGameBlocks', data)
                  this.$toast.add({
            severity: 'success', summary: this.$t("gameBlock_added"), life: 4000,
          })
                  this.$emit('update-block');
              } catch (error) {
                  this.$toast.add({
            severity: 'error', summary: this.$t("just_error"), life: 4000,
          })
              } finally{
                  game.loadingItem = false
              }
          },
          async applyFilters(){
              this.localLoadingFlag = true
              try {
                await this.$store.dispatch('gameBlocks/getGamesArray', this.filters);
                
              } catch (error) {
                this.$toast.add({
          severity: 'error', summary: this.$t("just_error"), life: 4000,
        })
              } finally{

                this.localLoadingFlag = false
                this.$store.commit('paggination/SET_LOADING_FLAG', false);
              }
          },
        },
    }
</script>

<style lang="scss" scoped>
.main-item {
    overflow: auto;
    max-height: 575px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    position: relative;
    height: 100%;
    &__game {
      padding: 3px 8px;
      display: flex;
      align-items: center;
      border: 1px solid var(--surface-500);
      border-radius: 4px;
      background-color: var(--surface-200);
    //  &:nth-of-type(odd) {
    //  background-color: var(--surface-100);
    //}
//
    //&:nth-of-type(even) {
    //  background-color: var(--surface-300);
    //}

    &._active{
      border: 1px solid var(--pink-700);
      background-color: var(--pink-200) !important;
    }
    }
    &__column{
      display: flex;
      align-items: center;
      flex: 0 1 33.333%;
      &_actions{
        justify-content: flex-end;
      }
      &_main{
        //flex: 1 1 100%;
      }
    }
    &__drag{
      margin-right: 5px;
      i{
        color: var(--text-color-secondary);
        ._active &{
          color: var(--bluegray-900);
        } 
      }
    }
    &__image {
      flex: 0 0 50px;
      width: 50px;
      height: 50px;
      position: relative;
      margin-right: 4px;
      ._no-active &{
        filter: grayscale(1);
      }
      span{
        display: flex;
        justify-content:center;
        align-items: center;
        background-color: var(--red);
        color: var(--white);
        min-width: 20px;
        height: 20px;
        position: absolute;
        padding: 0 3px;
        border-radius: 50px;
        right: -5px;
        top: -5px;
        z-index: 2;
      }
      img{
        border-radius: 16px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    &__name {
      flex: 1 1 100%;
      margin: 0 8px;
      ._provider &{
        display: flex;
        justify-content:center;
        align-items: center;
      }
    }
    &__actions {
      display: flex;
      justify-content:center;
      align-items: center;
    }
    &__edit {
    }
  }
</style>