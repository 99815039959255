<template>
    <div>
      <Button @click="toOpenDialog" :label="$t('add_new_block')" icon="pi pi-plus" class="mb-1 p-button-sm p-button-success" />
      <drop-list 
      v-if="!localLoadingFlag"
      :items="localOrders" 
      class="main-item" 
      @reorder="$event.apply(localOrders)">
        <template v-slot:item="{ item, index }">
          <drag @dragend="draggbleChanged" 
          handle=".main-item__drag" 
          @click="toSelectBlock(item, index)"
          :data="item" 
          class="main-item__game _cursor-pointer" 
          :key="item.block_id"
          :class="{'_active' : item.block_name === selectBlock.block_name, '_no-active': !item.is_active }"
          >
          <div class='main-item__column'>
        <div class="main-item__drag _cursor-grab">
          <v-icon>mdi-menu</v-icon>
          </div>
            <div class="main-item__image c-white">
              {{ index + 1 }}
            </div>
          </div>
            <div class='main-item__column main-item__column_main'>
            <div class="main-item__name">
              <InputText type="text" 
              v-if="item.block_name === selectBlock.block_name"
              v-model="item.block_name" 
              class="p-inputtext-sm" 
              style="max-width: 200px;" :placeholder="$t('name')" />
              <span v-else>{{ item.block_name }} </span>
            </div>
          </div>
          <div class='main-item__column main-item__column_actions'>
          <div class="main-item__actions space-x-1">
            <span class="lh-1 white-space custom-badge _main" v-if="!item.is_active"> {{ $t('not_active') }}</span>
              <template v-if="item.block_name === selectBlock.block_name">
                  <InputSwitch 
                  :disabled="item.loadingBlock" 
                  v-if="!item.loadingBlock"
                  class="p-inputswitch-success" v-model="item.is_active" />
                  <ProgressSpinner 
                  v-else
                  style="width:30px;height:30px" strokeWidth="4" animationDuration=".5s"/>
                  <!-- item.loadingBlock -->
                  <!-- @change="toggleGameBlock(item)" -->
                <Button 
                :disabled="item.loadingBlock || localLoadingFlag" 
                :loading="item.loadingBlock || localLoadingFlag" 
                @click="toUpdateBlock(item)" 
                icon="pi pi-save" 
                class="p-button-sm p-button-success px-8" 
                />
              </template>
              <Button v-else 
              :disabled="localLoadingFlag" 
              :loading="localLoadingFlag"
              icon="pi pi-pencil" 
              class="p-button-sm p-button-warning px-8" 
              />
            <Button icon="pi pi-trash" @click="toDeleteBlock(item)" 
            :disabled="item.loadingBlock"
            class="p-button p-component p-button-icon-only p-button-danger p-button-rounded ml-2" />
          </div>
        </div>
    </drag>
  </template>
</drop-list>
<item-loading v-else />
<!-- Create -->
  <v-dialog v-model="createModal" scrollable content-class="br-16" max-width="550px" :persistent="formLoading">
    <v-card>
      <v-card-title class="pa-3 justify-center">
                    <h3 class="font-weight-bold justify-center">
                      {{ $t('add_new_block') }}
                    </h3>
          </v-card-title>
          <ValidationObserver ref="observer" v-slot="{ invalid, validate }"> 
          <v-card-text class="pb-0 pt-1 text-left space-y-2 form-block">
            <ValidationProvider
            :name="$t('name')"
            rules="required|min3"
            v-slot="{ errors, validate, validated }"
          > 
            <h4 class="c-text white-space title-input">{{ $t('name') }}<span class="c-red">*</span>:</h4>
            <div class="d-flex flex-column wrapper-input">
              <!-- :minFractionDigits="2" -->
              <InputText 
        v-model="newBlock.name"
        :placeholder="$t('name')"
        aria-describedby="name-help" 
        :disabled="formLoading" 
          :loading="formLoading" 
        class="fw" 
        :class="{ 'p-invalid' : errors.length > 0 }"
        />
              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
            <ValidationProvider
            :name="$t('block_order')"
            rules="required"
            v-slot="{ errors, validate, validated }"
          > 
          <h4 class="c-text white-space title-input">{{ $t('block_order') }}<span class="c-red">*</span>:</h4>
            <div class="d-flex flex-column wrapper-input">
              <!-- :minFractionDigits="2" -->
              <v-menu v-model="orderMenu" 
        offset-y
            nudge-left="0" nudge-right="0">
            <template v-slot:activator="{ on }">
                <span class="p-input-icon-right fw" v-on="on" >
                  <i class="pi pi-angle-down" />
                  <InputText 
                  v-model="newBlock.block_order"
                  readonly class="p-inputtext-sm fw" :placeholder="$t('block_order')">
                  </InputText>
                </span>
            </template>
              <Listbox v-model="newBlock.block_order" 
              :options="availableOrders"
              @change="orderMenu = false"
              :emptyFilterMessage="$t('no_data')"
              style="width:auto;" />
          </v-menu>
              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
        <span class="form-switch"> 
          <h4 class="c-text white-space title-input">
            {{ $t('active') }}:
          </h4>
          <div class="wrapper-input d-flex">
            <InputSwitch class="p-inputswitch-success" v-model="newBlock.is_active" />
          </div>
      </span>
  </v-card-text>
            <v-card-actions>
                <Button
                @click="handleSubmit(validate)"
                :disabled="!isBlockValid || formLoading"
                :loading="formLoading"
                icon="pi pi-save"
                         :label="$t('save')"
                           class="p-button-sm p-button-success fw ma-0" />
              </v-card-actions>
            </ValidationObserver>
            </v-card>
            </v-dialog>
</div>
</template>

<script>
import { Drag, DropList } from "vue-easy-dnd";
    export default {
        name: "GameBlocks",
        components:{
      Drag,
      DropList,
    },
    props:{
      blocks:{
        type: Array,
        required: true,
      },
      loadingFlag:{
        type: Boolean,
        required: true,
      },
      triggerReset: {
        type: Boolean,
        required: true
      },
      triggerSave: {
        type: Boolean,
        required: true
      },
      triggerUpdateBlock: {
        type: Boolean,
        required: true
      },
    },
    mounted(){
    },
    watch:{
      loadingFlag: {
            immediate: true,
            handler: function (val, oldVal) {
              // console.log(val)
              this.localLoadingFlag = val
            }
          },
      blocks:{
        handler(newValue){
          if(newValue){
            // console.log(newValue);
            this.localOrders = [...newValue];
            this.selectBlock = this.blocks[0];
            this.toReceiveGameBlockGames(this.blocks[0]);
          }
        },
        // deep: true,
        immediate: true,
      },
      selectBlock:{
        handler(newValue){
          if(newValue && newValue.block_id){
            // console.log(newValue);
            this.toReceiveGameBlockGames(newValue);
            //this.$emit('block-games', {items: newValue.games, blockId: newValue.block_id})   
          }
        },
        // deep: true,
        //immediate: true,
      },
      triggerReset(newVal) {
      if (newVal) {
        this.reset();
      }
    },
    triggerSave(newVal) {
      if (newVal) {
        this.sendData();
      }
    },
    triggerUpdateBlock(newVal) {
      if (newVal) {
        this.holdSameBlock();
      }
    },
    
    },
    data() {
      return {
        orderMenu: false,
        localLoadingFlag: false,
        formLoading: false,
        createModal: false,
        isBlockValid: true,
        newBlock:{
          name: '',
          block_order: '',
          is_active: true,
        },
        localOrders: [],
        numberSelectedBlock: 0,
        selectBlock: {
          block_order: '', 
          block_id: '',
          block_name: '',
          is_active: '',
        },
        validation: {
          name: true,
          order: true,
        },
      }
    },
    computed:{
      availableOrders(){
        if (this.localOrders.length) {
          const allNumbers = Array.from({ length: this.localOrders.length + 5 }, (_, i) => i + 0);
      const blockOrders = this.localOrders.map(block => block.block_order);
      return allNumbers.filter(number => !blockOrders.includes(number));
        } else{
          return null
        }
      },
      // isBlockSelected:{
      //   get(currentBlock){
        
      //     return currentBlock.block_name === this.selectBlock.block_name;
      //   }
      // },
    },
    methods:{
      handleSubmit(validate){
        validate().then(valid => {
          if (valid) {
            this.createGameBlock();
          }
        })
      },
      async toReceiveGameBlockGames(block){
        // console.log('block', block.block_id);
        if (!block.gamesLoaded) {
          this.$emit('loading-start')
          block.loadingBlock = true;
          try {
            const filters = {
              page: 1,
              size: 50,
              game_block_id: block.block_id,
            };
            block.games = await this.$store.dispatch('gameBlocks/awaitGetGameBlockGames', filters);
            // console.log(block.games);
            
            this.$emit('block-games', {items: block.games, blockId: block.block_id})   
            block.gamesLoaded = true;
          } finally {
            block.loadingBlock = false;
            this.$emit('loading-end');
          }
        } else{
          this.$emit('block-games', {items: block.games, blockId: block.block_id})
        } 
      },
      reset(){
        this.localOrders = [...this.blocks];
      },
      sendData(){
        const order = this.localOrders.length > 0 ? this.blocks[0].block_order : 0;
        this.$emit('save-items', {items: this.localOrders, first: order});
      },
      toOpenDialog(){
        this.createModal = true;
        // setTimeout(() => {
        //   this.$refs.blockForm.resetValidation();
        // }, 0);
      },
      async toSelectBlock(block, index){
        this.numberSelectedBlock = index;
        this.selectBlock = block;

      },
      async toDeleteBlock(block){
        this.localLoadingFlag = true;
        const blockId = block.block_id;
        try {
          await this.$store.dispatch('gameBlocks/deleteGameBlock', blockId);
          this.$toast.add({
          severity: 'success', summary: this.$t("gameBlock_deleted"), life: 4000,
        })
        this.selectBlock = {
          block_order: '', 
          block_id: '',
          block_name: '',
          is_active: '',
        },
        this.$emit('block-games', {items: [], blockId: null})  
        this.$emit('update-block')
        } catch (error) {
          this.$toast.add({
          severity: 'error', summary: this.$t("just_error"), life: 4000,
        })
        } finally{
          this.localLoadingFlag = false;
        }
      },
      draggbleChanged() {
        this.$emit('draggble-changed');
        this.$root.$emit('setBlock');
      },
      async toGetGameBlocks(){
        try {
            await this.$store.dispatch('gameBlocks/awaitGetGameBlocks');
          } catch (error) {
            this.$toast.add({
                severity: 'error', summary: this.$t("just_error"), life: 4000,
            });
          }
    },
      async holdSameBlock(number){
        await this.toGetGameBlocks();
        this.selectBlock = this.localOrders[number ? number : this.numberSelectedBlock];
        this.setLocalOrders();
      },
      // async toRefreshBlocks(){
      //   await this.$store.dispatch("gameBlocks/awaitGetGameBlocks");
      //   this.setLocalOrders();
      // },
      async setLocalOrders(){
          this.localOrders = [...this.blocks];
      },
      async createGameBlock(){
        this.formLoading = true;
        this.localLoadingFlag = true;
        this.validation = {
          name: true,
          order: true,
        }
        const res = this.checkValid();
        if (res) {
          this.formLoading = false;
          this.localLoadingFlag = false;
          return;
        }
        const create = this.newBlock;
        const blockData = {
          name: create.name,
          block_order: create.block_order,
          is_active: create.is_active,
        };
        try {
          await this.$store.dispatch('gameBlocks/awaitCreateGameBlocks', blockData)
          this.$toast.add({
            severity: 'success', summary: this.$t("gameBlock_success_created"), life: 4000,
          })
          this.createModal = false;
          this.newBlock = {
          name: '',
          block_order: 0,
          is_active: true,
          }
          // await this.toGetGameBlocks();
          this.setLocalOrders();
        } catch (error) {
          this.$toast.add({
            severity: 'error',
            detail: error.response.status === 500 ? error.response.data : error.response.data.detail,
            summary: this.$t("just_error"), life: 4000,
          })
        } finally{
          this.formLoading = false;
          this.localLoadingFlag = false;
        }
      },
      async toUpdateBlock(block){
        block.loadingBlock = true;
        const blocksData = [{
          block_order: block.block_order, 
          block_id: block.block_id,
          block_name: block.block_name,
          is_active: block.is_active,
        }];

        try {
          await this.$store.dispatch('gameBlocks/awaitUpdateGameBlocks', blocksData)
          this.$toast.add({
            severity: 'success', summary: this.$t("gameBlock_is_changed"), life: 4000,
          })
          block.loadingBlock = false;
          this.selectBlock = {
          block_order: '', 
          block_id: '',
          block_name: '',
          is_active: '',
        };
        } catch (error) {
          this.$toast.add({
          severity: 'error', summary: this.$t("just_error"), life: 4000,
        })
          
        } 
      },
    },
    }
</script>

<style lang="scss" scoped>
.drop-list{
  row-gap: 2px;
  display: flex;
  flex-direction: column;
}
.main-item {
  overflow: auto;
  max-height: 575px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding-top: 3px;
  &__game {
    padding: 3px 8px;
    display: flex;
    align-items: center;
    border: 1px solid var(--surface-500);
    border-radius: 4px;
    background-color: var(--surface-200);
    &:nth-of-type(even) {
    }

    &:nth-of-type(odd) {
    }
  &._active{
    border: 1px solid var(--pink-700);
    background-color: var(--pink-200) !important;
  }
  }
  &__column{
    display: flex;
    align-items: center;
    flex: 0 1 33.333%;
    &_actions{
      justify-content: flex-end;
    }
    &_main{
      //flex: 1 1 100%;
    }
  }
  &__drag{
    margin-right: 5px;
    i{
      color: var(--text-color-secondary);
      ._active &{
        color: var(--bluegray-900);
      } 
    }
  }
  &__image {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    position: relative;
    margin-right: 4px;
      padding: 0 5px;
      background-color: var(--main-flamingo);
      display: flex;
      justify-content:center;
      align-items: center;
      cursor: pointer;
      border-radius: 16px;
    ._no-active &{
      filter: grayscale(1);
    }
    span{
      display: flex;
      justify-content:center;
      align-items: center;
      background-color: var(--red);
      color: var(--white);
      min-width: 20px;
      height: 20px;
      position: absolute;
      padding: 0 3px;
      border-radius: 50px;
      right: -5px;
      top: -5px;
      z-index: 2;
    }
    img{
      border-radius: 16px;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  &__name {
    flex: 1 1 100%;
    margin: 0 8px;
      display: flex;
      justify-content:center;
      align-items: center;
  }
  &__actions {
    display: flex;
    justify-content:center;
    align-items: center;
  }
  &__edit {
  }
}
</style>