<template>
    <div class="main-item" >
        <drop-list 
        v-if="!loadingFlag"
        :items="localOrders" 
        class="list" 
        @reorder="$event.apply(localOrders)">
          <template v-slot:item="{ item, index }">
            <drag  @dragend="draggbleChanged" 
            handle=".main-item__drag" 
            :data="item"
             class="main-item__game" :key="item.id"
             :class="{ '_no-active': !item.active }"
             >
             <div class='main-item__column'> 
          <div class="main-item__drag _cursor-grab">
            <v-icon>mdi-menu</v-icon>
        </div>
        <div class="main-item__image" v-if="item.logo">
          <span>{{ index + 1 }}</span>
          <v-lazy v-model="isActive">
            <v-img height="50px" max-width="50px" 
            @load="isActive = true" 
            :src="item.logo" 
            />
          </v-lazy>
        </div>
        <button type="button" v-if="item.aggregator" 
                @click.stop="$router.push('/game-fee-settings/aggregators')"
                class="custom-badge _main"
                >
                  <span>{{ item.aggregator }} </span>
                </button>
      </div>
      <div class='main-item__column main-item__column_main'>

        <div class="main-item__name" v-if="item.name">
          {{ item.name }}
        </div>
      </div>
      <div class='main-item__column main-item__column_actions'>

        <div class="main-item__actions space-x-1">
          <span class="lh-1 white-space custom-badge _main" v-if="!item.active"> {{ $t('not_active') }}</span>
          <Button :disabled="item.loadingGame" :loading="item.loadingGame" 
          @click="toDeleteGameByBlock(item)" 
          icon="pi pi-minus" class="p-button-sm p-button-danger px-8" />
        </div>
          </div>
    </drag>
  </template>
</drop-list>
<item-loading v-else />
    </div>
</template>

<script>
 import { Drag, DropList } from "vue-easy-dnd";
//   import { mapGetters } from "vuex";
  export default {
    name: "GamesByBlock",
    components:{
      Drag,
      DropList,
    },
    props: {
      loadingFlag: Boolean,
        games: {
            type: Array,
            required: true,
        },
        blockId: {
            type: Number,
            required: true,
        },
        triggerReset: {
        type: Boolean,
        required: true
      },
      triggerSave: {
        type: Boolean,
        required: true
      },
    },
    watch:{
        games:{
        handler(newValue){
          if(newValue){
            // console.log(newValue);
            this.localOrders = newValue.map(item => ({
                ...item,
                loadingGame: false,
            }));
          }
        },
        deep: true,
        immediate: true,
      },
      triggerReset(newVal) {
      if (newVal) {
        this.reset();
      }
    },
    triggerSave(newVal) {
      if (newVal) {
        this.sendData();
      }
    },
    },
    data() {
            return {
                isActive: false,
                localOrders: [],
            }
        },
        methods: {
          reset(){
        this.localOrders = [...this.games];
      },
      sendData(){
        const order = this.localOrders.length > 0 ? this.games[0].game_order : 0;
        this.$emit('save-items', {items: this.localOrders, first: order});
      },
            draggbleChanged(){
                this.$emit('draggble-changed');
                 this.$root.$emit('setBlock');
            },
           async toDeleteGameByBlock(game){
                game.loadingGame = true;
                let gameData = {
                    game_id: game.game_id,
                    block_id: this.blockId,
                }
                try {
                    await this.$store.dispatch('gameBlocks/awaitDeleteGameToGameBlocks', gameData);
                    const index = this.localOrders.findIndex(order => order.game_id === gameData.game_id);
                    if (index !== -1) {
                      this.localOrders.splice(index, 1);
                    }
                    this.$emit('update-block');
                      
                      this.$toast.add({
                      severity: 'success', summary: this.$t("gameBlock_game_deleted"), life: 4000,
                    })
                  } catch (error) {
                      this.$toast.add({
                    severity: 'error', summary: this.$t("just_error"), life: 4000,
                  })
                  } finally{
                    game.loadingGame = false;
                  }

            },
        },
}
</script>

<style lang="scss" scoped>
.drop-list{
  row-gap: 2px;
  display: flex;
  flex-direction: column;
}
.main-item {
  overflow: auto;
  max-height: 575px;
  display: flex;
  flex-direction: column;
  
  position: relative;
  height: 100%;
  padding-top: 3px;
  &__game {
    padding: 3px 8px;
    display: flex;
    
    align-items: center;
    border: 1px solid var(--surface-500);
    border-radius: 4px;
    background-color: var(--surface-200);
    &:nth-of-type(odd) {
    }

    &:nth-of-type(even) {
      //background-color: var(--surface-300);
    }


    &._active {
      border: 1px solid var(--pink-700);
      background-color: var(--pink-200) !important;
    }
    &._no-active {
      border: 1px solid var(--gray-700);
      //background-color: var(--gray-400) !important;
    }
  }
  &__column{
    display: flex;
    align-items: center;
    flex: 0 1 33.333%;
    &_actions{
      justify-content: flex-end;
    }
    &_main{
      //flex: 1 1 100%;
    }
  }
  &__drag {
    margin-right: 5px;
    i{
      color: var(--text-color-secondary);
      ._active &{
        color: var(--bluegray-900);
      } 
    }
  }
  
  &__image {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    position: relative;
    margin-right: 4px;
    display: flex;
    line-height: 1;
    ._no-active & {
      filter: grayscale(1);
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--red);
      color: var(--white);
      min-width: 20px;
      height: 20px;
      position: absolute;
      padding: 0 3px;
      border-radius: 50px;
      right: -5px;
      top: -5px;
      z-index: 2;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
  
  &__name {
    flex: 1 1 auto;
    margin: 0 8px;
    color: var(--text-color);
    text-transform: capitalize;
    ._provider & {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ._active &{
      color: var(--bluegray-900);
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__edit {}
}
</style>